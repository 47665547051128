import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles({
  inRange: {
    color: '#72c89e',
    fontWeight: 'bold',
  },
});

type Props = {
  birthdate?: string;
};

const ageInRange = (age: number | null) => (age ? (age >= 18 && age <= 30) || age >= 60 : null);

const Age: React.FC<Props> = ({ birthdate }) => {
  const classes = useStyles();
  const age = birthdate ? moment().diff(birthdate, 'year') : null;

  return <span className={clsx(ageInRange(age) && classes.inRange)}>{age}</span>;
};

export default Age;
