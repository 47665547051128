import {
  Badge,
  Box,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import StatusChip, { Status } from 'components/StatusChip';
import { UsersWithBirthdayQuery, Roles } from 'generated/graphql';
import { getInitials } from 'lib/utils';
import Link from 'next/link';
import Age from '../Users/Age';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
  },
  table: {
    overflow: 'hidden',
  },
  tableRow: {
    cursor: 'pointer',
  },
  hostBadge: {
    '& span': {
      backgroundColor: '#72c89e',
    },
  },
  published: {
    color: '#72c89e',
  },
  unpublished: {
    color: '#f26b5b',
  },
  visibilityIcon: {
    fontSize: 12,
    marginLeft: -15,
    marginTop: 30,
  },
}));

const StyledTableCell = withStyles({
  sizeSmall: {
    padding: 6,

    '&:first-child': {
      paddingLeft: 16,
    },
  },
})(TableCell);

type Props = {
  users: UsersWithBirthdayQuery['usersWithBirthday'];
};

type UserProps = {
  user: UsersWithBirthdayQuery['usersWithBirthday'][number];
};

const getTypeLetter = (user: UsersWithBirthdayQuery['usersWithBirthday'][number]) => {
  if (user.type) {
    return String.prototype.toUpperCase.call(user.type)[0];
  }
};

const UserBirthday: React.FC<UserProps> = ({ user }) => {
  const classes = useStyles();

  return (
    <Link href="/users/[id]" as={`/users/${user.id}`} passHref>
      <TableRow hover className={classes.tableRow}>
        <StyledTableCell>
          <Box alignItems="center" display="flex">
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item>
                <Badge
                  overlap="rectangular"
                  badgeContent={
                    user.profile.avatar?.url ? (
                      <VisibilityIcon className={clsx(classes.visibilityIcon, classes.published)} />
                    ) : (
                      <VisibilityOffIcon
                        className={clsx(classes.visibilityIcon, classes.unpublished)}
                      />
                    )
                  }
                >
                  <Badge
                    color={user.type === Roles.Host ? 'secondary' : 'primary'}
                    className={user.type === Roles.Host ? classes.hostBadge : undefined}
                    overlap="circular"
                    badgeContent={getTypeLetter(user)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    <Avatar
                      className={classes.avatar}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      url={user.profile?.avatar?.url}
                    >
                      {getInitials(`${user.firstName} ${user.lastName}`)}
                    </Avatar>
                  </Badge>
                </Badge>
              </Grid>
              <Grid item>
                <StatusChip label={user.status[0].toUpperCase()} status={user.status as Status} />
              </Grid>
            </Grid>
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Grid container spacing={2}>
            <Grid item>
              <Typography color="textPrimary" variant="body1">
                {`${user.firstName} ${user.lastName}`}
              </Typography>
            </Grid>
            {user.bannedAt && (
              <Grid item>
                <StatusChip label="B" status="banned" />
              </Grid>
            )}
          </Grid>
        </StyledTableCell>
        <StyledTableCell>
          <Age birthdate={user.profile?.birthdate} />
        </StyledTableCell>
      </TableRow>
    </Link>
  );
};

const Birthdays: React.FC<Props> = ({ users }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UserBirthday user={user} key={user.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Birthdays;
