import { CircularProgress } from '@material-ui/core';
import { MeQuery, useMeQuery } from 'generated/graphql';
import { useRouter } from 'next/router';

interface WithAuthProps {
  viewer: NonNullable<MeQuery['me']>;
}

const withAuth = <T extends WithAuthProps = WithAuthProps>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof WithAuthProps>> => {
  const ComponentWithAuth = (props: Omit<T, keyof WithAuthProps>) => {
    const { asPath, push } = useRouter();
    const { loading, data } = useMeQuery();

    if (loading) {
      return (
        <div style={{ height: '100vh', width: '100%', display: 'grid', placeItems: 'center' }}>
          <CircularProgress />
        </div>
      );
    }

    if (!data?.me) {
      push({ pathname: '/login', query: { redirectTo: asPath } });
      return <div />;
    }

    return <WrappedComponent {...(props as T)} viewer={data.me} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
