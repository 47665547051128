import Dashboard from 'components/Dashboard';
import withAuth from 'components/withAuth';
import { NextPage } from 'next';
import {
  MeQuery,
} from 'generated/graphql';

const DashboardPage: NextPage<{ viewer: NonNullable<MeQuery['me']> }> = ({ viewer }) => <Dashboard viewer={viewer} />;

export default withAuth(DashboardPage);
