import { forwardRef, PropsWithRef } from 'react';
import { Helmet } from 'react-helmet';

export interface PageProps extends PropsWithRef<JSX.IntrinsicElements['div']> {
  children: React.ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...rest }, ref) => {
  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.displayName = 'Page';

export default Page;
