import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Link from 'next/link';

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface Props extends React.ComponentPropsWithoutRef<typeof Typography> {
  as?: string;
  href: string;
  target?: string;
}

const TypographyLink: React.FC<Props> = ({ as, href, className, color = 'primary', ...props }) => {
  const classes = useStyles();

  return (
    <Link href={href} as={as} passHref>
      <Typography
        className={clsx(classes.root, className)}
        component="a"
        color={color}
        {...props}
      />
    </Link>
  );
};

export default TypographyLink;
