import { Avatar } from '@material-ui/core';

type Props = {
  className?: string;
  variant?: 'circle' | 'circular' | 'rounded' | 'square';
  firstName: string;
  lastName: string;
  url?: string;
  children?: React.ReactNode;
};

const CustomAvatar: React.FC<Props> = ({
  children,
  className,
  firstName,
  lastName,
  url,
  variant,
}) => (
  <Avatar className={className} alt={[firstName, lastName].join(' ')} src={url} variant={variant}>
    {children}
  </Avatar>
);

export default CustomAvatar;
