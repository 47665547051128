import { Chip } from '@material-ui/core';

export type Status = 'member' | 'rejected' | 'banned' | 'verified';

type Props = {
  label: string;
  status: Status;
};

const getColor = (status: Status) => {
  if (status === 'member') {
    return 'primary';
  }
  if (status === 'rejected' || status === 'banned') {
    return 'secondary';
  }
  return 'default';
};

const StatusChip: React.FC<Props> = ({ label, status }) => (
  <Chip size="small" label={label} color={getColor(status)} />
);

export default StatusChip;
