import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'components/Page';
import Payments from 'components/Payments';
import {
  MeQuery,
  UsersWithBirthdayQuery,
  useUsersWithBirthdayQuery,
} from 'generated/graphql';
import { actions as notificationsActions } from 'lib/redux/notifications';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Birthdays from './birthdays';

const { addNotifications } = notificationsActions;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (theme.palette.background as any).dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Progress: React.FC = () => (
  <Grid container justifyContent="space-around">
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

const BirthdaysContent: React.FC<{
  loading: boolean;
  users?: UsersWithBirthdayQuery['usersWithBirthday'];
}> = ({ loading, users }) => {
  if (loading || !users) {
    return <Progress />;
  }

  return <Birthdays users={users} />;
};

const Dashboard = ({ viewer }: { viewer: MeQuery['me'] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    loading: loadingUsersWithBirthday,
    error: errorUsersWithBirthday,
    data: dataUsersWithBirthday,
  } = useUsersWithBirthdayQuery();

  if (errorUsersWithBirthday) {
    dispatch(addNotifications([{ severity: 'error', message: errorUsersWithBirthday.message }]));
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Box>
          <Grid container spacing={3} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3" color="textPrimary">
                {`Welcome, ${viewer?.firstName}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Card>
            <CardHeader title={`Birthdays (${moment().format('YYYY-MM-DD')})`} />
            <Divider />
            <CardContent>
              <BirthdaysContent
                loading={loadingUsersWithBirthday}
                users={dataUsersWithBirthday?.usersWithBirthday}
              />
            </CardContent>
          </Card>
        </Box>

        <Box mt={3}>
          <Card>
            <CardHeader title="Last payments" />
            <Divider />
            <CardContent>
              <Payments />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default Dashboard;
